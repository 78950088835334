import React from 'react'

export default function Pittfalls() {
    let backgroundStyle = {
        background: "#292929",
        padding: '0 0 0px 0',
    }

    let titleStyle = {
        fontSize: '60px',
        color: '#FFF',
        textAlign: 'center',
        margin: '40px 0 0px 0',
    }
    
    
    let subtitleStyle = {
        fontSize: '46px',
        color: '#FFF',
        textAlign: 'center',
        margin: '20px 0 20px 0',
    }

    let liStyle = {
        margin: '0 10px 20px 0',
    }

  return (
    <div style={{width: '100%'}}>
        <div
            style={{display: 'flex'}}
        >
            <div style={{width: '7vw'}}></div>
            <div style={{width: '84vw'}}>
                
                <div style={subtitleStyle}>
                    Where does your cataract process get stuck?
                </div>
                <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                    <div 
                        style={{
                            background: "#F7F0AD", 
                            width: '30%',
                            borderRadius: '5px',
                            padding: '5px 5px 20px 5px',
                        }}
                    >
                        <p style={{textAlign: 'center', margin: '0', fontSize: '30px'}}>
                            Patients
                        </p>
                        <ul>
                            <li style={liStyle}>
                                Don't understand explanation of lens options
                            </li>
                            <li style={liStyle}>
                                Debating unreasonable expectations
                            </li>
                            <li style={liStyle}>
                                Don't undertstand near, far, intermediate, and astigmatism
                            </li>
                            <li style={liStyle}>
                                Blame doctor for picking the "wrong" lens
                            </li>
                        </ul>
                    </div>
                    <div 
                        style={{
                            background: "#e7ccf1", 
                            width: '30%',
                            borderRadius: '5px',
                            padding: '5px 5px 20px 5px',
                        }}
                    >
                        <p style={{textAlign: 'center', margin: '0', fontSize: '30px'}}>
                            Staff
                        </p>
                        <ul>
                            <li style={liStyle}>
                                Lens model mix-ups (CCA0T0 vs CCWET0 vs CCWTT0)
                            </li>
                            <li style={liStyle}>
                                Wrong-side consents
                            </li>
                            <li style={liStyle}>
                                Offering contraindicated lens or quoting incorrect price
                            </li>
                            <li style={liStyle}>
                                Preops bottleneck if surgery coordinator is gone
                            </li>
                        </ul>
                    </div>
                    <div 
                        style={{
                            background: "#d2e5f6", 
                            width: '30%',
                            borderRadius: '5px',
                            padding: '5px 5px 20px 5px',
                        }}
                    >
                        <p style={{textAlign: 'center', margin: '0', fontSize: '30px'}}>
                            Doctors
                        </p>
                        <ul>
                            <li style={liStyle}>
                                Tired of long lens discussions
                            </li>
                            <li style={liStyle}>
                                Uncomfortable using premium lenses
                            </li>
                            <li style={liStyle}>
                                Can't take same cataract preop workflow with them from clinic to clinic
                           </li>
                            <li style={liStyle}>
                                Have different lens preferences and cutoffs than other doctors in the clinic
                           </li>
                        </ul>
                    </div>
                </div>
                
            </div>
            <div style={{width: '7vw'}}></div>
        </div>
    </div>
                    
  )
}
