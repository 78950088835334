import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

import FooterDark from "./partials/FooterDark";
import Navbar from "./partials/Navbar"
import Quote from "./partials/Quote";
import TermsOfUse from './tutorial/TermsOfUse'

export default function LandingPage(props) {

  const [transform, setTransform] = useState('')
  const [learnLinkHover, setLearnLinkHover] = useState(false)
  const [loginLinkHover, setLoginLinkHover] = useState(false)

  useEffect(() => {
      window.addEventListener('scroll', handleScroll);
    }, []);


  const handleScroll = (e) => {
    let r = window.scrollY;
    let u = r/6
    setTransform(u)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  let blueLinkStyleTrue = {
    fontSize: '22px',
    margin: '0 30px 0 30px',
    padding: '20px',
    background: '#007bff',
    border: '1px solid #007bff',
    borderRadius: '5px',
    color: '#FFF',
  }

  let blueLinkStyleFalse = {
    fontSize: '22px',
    margin: '0 30px 0 30px',
    padding: '20px',
    background: '#FFF',
    border: '1px solid #007bff',
    borderRadius: '5px',
    color: '#007bff',
  }


  return (
    <div
      style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      margin: '0 0 0px 0',
      position: 'relative',
    }}>
    <Navbar/>
      
      <h1
        style={{
          fontSize: '80px',
          fontWeight: '400',
          color: '#595959',
          margin: '40px 0 0 0',
          width: '100%',
          textAlign: 'center',
        }}
        >
        Cataract Preop
      </h1>
      <h3
        style={{
          fontSize: '30px',
          fontWeight: '400',
          color: '#595959',
          margin: '0 0 20px 0',
          width: '100%',
          textAlign: 'center',
        }}>

      </h3>
      <div
        onScroll={handleScroll}
        style={{
          margin: '0 0 50px 0',
          textAlign: 'center',
        }}
        >
        <img
          style={{
            transform: `rotate(${transform}deg)`,
            width: '300px',
            opacity: '0.6'
          }}
          src="logoImage.png"
          alt="logo"
          >
        </img>
      </div>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px 3vw 0px 3vw',
        margin: '0 0 100px 0'
        }}>
        <Link
          to={
            props.authVerify === true
            ? '/patientinfo'  
            : '/login'
          }
        >
          <button
            style={
              loginLinkHover === true
                ? blueLinkStyleTrue
                : blueLinkStyleFalse
              }
            onMouseOver ={() => setLoginLinkHover(true)}
            onMouseOut={() => setLoginLinkHover(false)}
            onClick={() => {
              props.setUserType('md')
            }}
            >I'm a clinic
          </button>
        </Link>
        <Link
          to={'/tutorial1'}
        >
          <button
            style={
              learnLinkHover === true
                ? blueLinkStyleTrue
                : blueLinkStyleFalse
              }
            onMouseOver ={() => setLearnLinkHover(true)}
            onMouseOut={() => setLearnLinkHover(false)}
            onClick={() => {
              props.setUserType('pt')
            }}
            >I'm a patient
          </button>
        </Link>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}
      >
     
        <Quote
          text={"Ever since I started using Cataract Preop, the preop process has become substantially easier. All my previous clinics required us to enter all the measurements manually on paper or have to use multiple websites to get a final result. With this preop website you can find everything in one place, and it saves you an incredible amount of time. I wish I would've known about this website so much sooner. It is incredible and I truly believe everyone that tries it will absolutely love it."}
          author={"Rachel H., COA"}
          location={"Riverton, Utah"}
          imageSrc={'testHeadshotF.jpeg'}
        />
        
        <Quote
          text={"This software has made the cataract preop process so much easier.  Patients can simulate their vision, get educated, and choose their lens at home, which means reduced time discussion lens options in clinic.  And, I no longer worry about incorrect lenses being offered or incorrect paperwork being filled out."}
          author={"Lyndon Tyler, MD"}
          location={"Riverton, UT"}
          imageSrc={'lyndonTyler.jpeg'}
        />
        
      </div>
      <FooterDark/>
    </div>
  );
}



        // <Quote
        //   text={"Cataract preop makes it so easy for patients to decide on the intraocular lens that they want."}
        //   author={"Jon D., COA"}
        //   location={"Atlanta, GA"}
        //   imageSrc={'testHeadshotF.jpeg'}
        // />
        
        // <Quote
        //   text={"It's like a surgery scheduler that doesn't move away and always gets it right."}
        //   author={"Jon D., COA"}
        //   location={"Atlanta, GA"}
        //   imageSrc={'testHeadshotF.jpeg'}
        // />


      //   <Quote
      //   text={"Incorrect lens offered to patient?  Mixed up paperwork?  Understaffed?  Cataract preop allows you, as the physician, to select and save your decision points for patients so that staff and patients are guided correctly."}
      //   author={"Lyndon Tyler, MD"}
      //   location={"Riverton, UT"}
      //   imageSrc={'lyndonTyler.jpeg'}
      // />