import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import lensGuide from './images/lensGuide.jpeg'
import advancedMode from './images/compareLenses.jpeg'
import FooterLight from '../partials/FooterLight';

import api from "../services/api";

export default function Tutorial5 (props) {


    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    
    const handleCheckout = async () => {
      try {
        const response = await api.post("/checkout", {
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ items: [{ id: "day-pass" }] })
        });
    
        const jsonResponse = response.data;
    
        if (jsonResponse.url) {
          window.location.assign(jsonResponse.url);
        }
    
      } catch (err) {
        console.error(err.message);
      }
    };

    let backgroundStyle = {
        background: "#292929",
        padding: '0 0 0px 0',
    }

    let titleStyle = {
        fontSize: '60px',
        color: '#FFF',
        textAlign: 'center',
        margin: '40px 0 0px 0',
    }
    
    
    let subtitleStyle = {
        fontSize: '46px',
        color: '#FFF',
        textAlign: 'left',
        margin: '20px 0 20px 0',
    }
    
    let listStyle = {
        fontSize: '24px',
        color: '#FFF',
        // textAlign: 'center',
        margin: '40px 0 0 0',
    }


    let buttonStyleTrue = {
        border: '1px solid #007bff',
        color: '#FFF',
        borderRadius: '10px',
        background: "#007bff",
        margin: '10px 5px 30px 5px',
        padding: '10px 20px',
        cursor: 'pointer',
        width: '340px',
        textAlign: 'center',
        textDecoration: 'none',
        // position: 'fixed',
        // zIndex: '3',
      }
      

    let pStyle = {
        margin: '0 0 4px 0',
        fontSize: "20px",
    }

    return(
        <div style={backgroundStyle}>
            <div
                style={{display: 'flex'}}
            >
            <div style={{width: '7vw'}}></div>
                <div style={{width: '84vw'}}>
                    <div 
                        style={{
                            color: '#FFF',
                            margin: '0 auto',
                            textAlign: 'center',
                            cursor: 'pointer',
                            textDecoration: 'underline',
                            position: 'relative'
                            }}
                    >
                            <Link
                                to='/login'
                                style={{
                                    textDecoration: 'none',
                                    color: '#FFF',
                                    position: 'absolute', 
                                    top: '10px',
                                    right: '0px',
                                    fontSize: "18px",
                                }}
                            >
                                Physician Portal
                            </Link>
                    </div>       
                    <p style={titleStyle}>Cataract Preop</p>
                    
                    <p style={{
                        fontSize: '34px',
                        color: '#FFF',
                        textAlign: 'center',
                        margin: '0px 0 20px 0',
                    }}>See Your Future</p>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <div 
                            style={buttonStyleTrue}
                            onClick={() => handleCheckout()}
                        >
                            <p style={pStyle}>
                                24-hour full access: $19.95
                            </p>
                        </div>
                        
                        <Link 
                            style={buttonStyleTrue}
                            to='/tutorial1'
                        >
                            <p style={pStyle}>
                                FREE Cataract tutorial
                            </p>
                        </Link>

                    </div>

                    <hr></hr>                
                    <div
                        style={{
                            display: 'flex',
                        }}
                    >
                        
                        <div
                            style={{
                                width: '40%',
                                margin: '0 0 60px 0'
                            }}
                        >
                            <p style={subtitleStyle}>Vision Simulator</p>
                            <p style={listStyle}>What will I see after surgery?</p>
                            <p style={listStyle}>Would I see better if I chose a different lens?</p>
                            <p style={listStyle}>Will it look different during the day versus during the night?</p>
                            <p style={listStyle}>Get answers to these questions using the Vision Simulator</p>
                        </div>
                        <div
                            style={{
                                width: '60%',
                                position: 'relative',
                            }}
                        >
            
                        <iframe 
                            width="649" 
                            height="344" 
                            src="https://www.youtube.com/embed/t8IiOLNhm0E?playlist=t8IiOLNhm0E&autoplay=1&loop=1"  //NB: playlist="id of video and NOT id of playlist" 
                            title="Simulator" 
                            frameBorder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" 
                            referrerPolicy="strict-origin-when-cross-origin" 
                            allowFullScreen
                            style={{
                                margin: "30px 20px 0 20px",
                                borderRadius: '20px',
                                borderBottom: '1px solid #494949',
                                position: 'absolute',
                                top: '0px',
                                left: '0px',
                            }}
                        ></iframe>
                        </div>
                    </div>

                    <hr
                        style={{
                            margin: '60px 0 40px 0',
                        }}
                    ></hr>
                    <div
                        style={{
                            display: 'flex',
                        }}
                    >
                        
                        <div
                            style={{
                                width: '40%'
                            }}
                        >
                            <p style={subtitleStyle}>Lens Guide</p>
                        
                            <p style={listStyle}>What are the pros and cons of my lens options?</p>
                            <p style={listStyle}>How much should I be paying out-of-pocket for this lens?</p>
                            <p style={listStyle}>Empower yourself by using the Lens Guide to learn more about your lens options.</p>
                        </div>
                        <div
                            style={{
                                width: '60%',
                            }}
                        >
                        <img
                            src={lensGuide}
                            alt='Cataract replacement intraocular lens guide'
                            style={{
                                width: '650px',
                                margin: "30px 20px 0 20px",
                                borderRadius: '20px',
                            }}
                        />


                        </div>
                    </div>
                
                    <hr
                        style={{
                            margin: '60px 0 40px 0',
                        }}
                    >          
                    </hr>
                    
                    <div
                        style={{
                            display: 'flex',
                        }}
                    >
                        
                        <div
                            style={{
                                width: '40%'
                            }}
                        >
                            <p style={subtitleStyle}>Advanced mode</p>
                            <p style={listStyle}>What eye measurements does my doctor need to know before cataract surgery?</p>
                            <p style={listStyle}>Am I getting an up-to-date lens?</p>
                            <p style={listStyle}>Compare lenses side-by-side</p>
                            <p style={listStyle}>             
                                The advanced features tailor the vision simulator to your eye measurements*
                            </p>
                        </div>
                        <div
                            style={{
                                width: '60%',
                            }}
                        >
                        <img
                            src={advancedMode}
                            alt='Advanced cataract lens guide'
                            style={{
                                width: '650px',
                                margin: "30px 20px 0 20px",
                                borderRadius: '20px',
                            }}
                        />


                        </div>
                    </div>
                    
                    <hr
                        style={{
                            margin: '60px 0 0px 0',
                        }}
                    >          
                    </hr>
                    
                        <p 
                            style={{
                                fontSize: '18px',
                                color: '#FFF',
                                margin: '10px 0 0 0',
                            }}
                        >
                            *Simulation. Does not replace the advice of your doctor.  All lens decisions are between you and your doctor
                        </p>   
                
                </div>
            </div>
            
            <FooterLight/>
        </div>
    )
} 


// does not need FDA approval according to https://www.fda.gov/medical-devices/device-software-functions-including-mobile-medical-applications/examples-software-functions-are-not-medical-devices #3