import React, { useState, useEffect } from 'react';
import api from "../services/api";
import { logout } from "../services/auth";

import { Link } from 'react-router-dom';


import { FaBars } from 'react-icons/fa';


export default function Navbar (props){

  const[pancake, setPancake] = useState("")
  const[pancakeClick, setPancakeClick] = useState(false)
  const[physicianName, setPhysicianName] = useState('')


  const getLoggedIn = async() => {

      try {
        const response = await api.get(`/user`);

        setPhysicianName(response.data.rows[0].physician_name);

      } catch (err) {
        console.error(err.message)
      }
    }

    useEffect(() => {
        getLoggedIn();
      }, []);


  const handleLogout = async () => {
    try {

      logout()
      window.location = `/login`

    } catch (err) {
      console.error(err.message)
    }
  }

  let handleScreenChange = () => {
    window.innerWidth < 900
    ? setPancake(true)
    : setPancake(false)
  }

  useEffect(() => {
    handleScreenChange();
    window.addEventListener("resize", handleScreenChange)
    return () => {
      window.removeEventListener("resize", handleScreenChange);
    };
  }, []);

  let handlePancakeClick = () => {
    pancakeClick === false
      ? setPancakeClick(true)
      : setPancakeClick(false)
  }

  let buttonStyle= {
      textDecoration: 'none',
      opacity: '0.75',
      color: '#000',
      background: 'none',
      border: 'none',
      fontFamily: '"Montserrat",sans-serif',
      fontSize: '1rem',
      margin: '10px',
      cursor: 'pointer',
  }
 
  let linkStyle= {
      textDecoration: 'none',
      opacity: '0.75',
      color: '#000',
      background: 'none',
      border: 'none',
      fontFamily: '"Montserrat",sans-serif',
      fontSize: '1rem',
      margin: '10px',
      cursor: 'pointer',
  }


  let headerStyle = {
    width: '94vw',
    height: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 3vw 0px 3vw',
    borderBottom: '0.5px solid #cccccc',
    color: '#fff',
    background: '#fff',
    zIndex: '10',
    position: 'fixed',
    top: '0',
    left: '0',
    opacity: '0.92',
  }


  let navHeader =
      <div>
        <h2 style={{
          fontFamily: '"Montserrat",sans-serif',
          fontSize: '1.5rem',
          padding: '0',
        }}
        >
          <Link to={
            physicianName.length > 1
            ? "/patientinfo"
            : "/"
          }
            style={{
              textDecoration: 'none',
              opacity: '0.75',
              color: '#000',
              background: 'none',
              border: 'none',
              fontFamily: '"Montserrat",sans-serif',
              fontWeight: '400',
              fontSize: '1.5rem',
              cursor: 'pointer',
            }}
            >
              {physicianName || "CataractPreop"}
          </Link>
        </h2>
      </div>


  return(
    <div>
      {pancake === true
        ? pancakeClick === true
          ? physicianName.length > 1
            ?
              <div style={{
                width: '100vw',
                height: '100vh',
                zIndex: '1',
                background: 'rgba(0, 0, 0, 0.6)',
                position: 'absolute',
                top: '0',
                left: '0',
                }}
                className="dontPrint"
                onClick={()=> handlePancakeClick()}
                >
                <header style={headerStyle}>
                  {navHeader}
                    <nav style={{
                      display: 'flex',
                      flexDirection: 'column',
                      position: 'fixed',
                      top: '0',
                      right: '0',
                      listStyle: 'none',
                      background: '#FFF',
                      padding: '30px',
                      zIndex: '2',
                    }}>
                      <li style={{
                        margin: '20px 40px 20px 0',
                      }}>
                        <Link to={'/patientinfo'}
                          style={linkStyle}
                        >
                           Patient Info
                         </Link>
                      </li>
                      <li style={{
                        margin: '20px 40px 20px 0',
                      }}>
                        <Link to={"/myaccount"}
                          style={linkStyle}
                        >
                          My account
                        </Link>
                      </li>
                      <li style={{
                        margin: '20px 40px 20px 0',
                      }}>
                        <button
                          style={buttonStyle}
                          onClick={() => handleLogout()}
                          >
                          Logout
                        </button>
                      </li>
                    </nav>
                  </header>
              </div>
            :
              <div style={{
                width: '100vw',
                height: '100vh',
                zIndex: '1',
                background: 'rgba(0, 0, 0, 0.6)',
                position: 'absolute',
                top: '0',
                left: '0',
                }}
                className="dontPrint"
                onClick={()=> handlePancakeClick()}
                >
                <header style={headerStyle}>
                  {navHeader}
                    <nav style={{
                      display: 'flex',
                      flexDirection: 'column',
                      position: 'fixed',
                      top: '0',
                      right: '0',
                      listStyle: 'none',
                      background: '#FFF',
                      padding: '30px',
                      zIndex: '2',
                    }}>
                      <li style={{
                        margin: '20px 40px 20px 0',
                      }}>
                        <Link to={"/login"}
                          style={linkStyle}
                           >
                           Login
                         </Link>
                      </li>
                      {/* <li style={{
                        margin: '20px 40px 20px 0',
                      }}>
                        <Link to={"/signup"}
                          style={linkStyle}
                        >
                          Sign-up
                        </Link>
                      </li> */}
                    </nav>
                  </header>
              </div>

          :
            <header
              style={headerStyle}
              className="dontPrint"
              >
              {navHeader}
              <nav
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  listStyle: 'none',
                }}
                onClick={()=> handlePancakeClick()}>
                <li style={{
                  margin: '20px 10px 20px 0',
                }}>
                  <FaBars
                    style={{
                        textDecoration: 'none',
                        opacity: '0.75',
                        color: '#000',
                        background: 'none',
                        border: 'none',
                        fontFamily: '"Montserrat",sans-serif',
                        fontSize: '2rem',
                    }}
                   />
                </li>
              </nav>
            </header>
          : physicianName.length > 1
            ?
            <header
              style={headerStyle}
              className="dontPrint"
              >
              <h2 style={{
                fontFamily: '"Montserrat",sans-serif',
                fontSize: '1.5rem',
                padding: '0',
              }}
              >
                <Link to={
                   physicianName.length > 1
                   ? "/patientinfo"
                   : "/"
                }
                  style={{
                    textDecoration: 'none',
                    opacity: '0.75',
                    color: '#000',
                    background: 'none',
                    border: 'none',
                    fontFamily: '"Montserrat",sans-serif',
                    fontWeight: '400',
                    fontSize: '1.5rem',
                    cursor: 'pointer',
                  }}
                  >
                    {physicianName || "CataractPreop"}
                </Link>
              </h2>
                <nav style={{
                  display: 'flex',
                  marginRight: '-15px',
                  listStyle: 'none',
                }}>
                  <li style={{
                    margin: 'auto 15px',
                  }}>
                    <Link to={"/patientinfo"}
                      style={linkStyle}
                    >
                      Patient Info
                    </Link>
                  </li>
                  <li style={{
                    margin: 'auto 15px',
                  }}>
                    <Link to={"/myaccount"}
                      style={linkStyle}
                    >
                      My account
                    </Link>
                  </li>
                  <li style={{
                    margin: 'auto 15px',
                  }}>
                    <button
                      style={buttonStyle}
                      onClick={() => handleLogout()}
                    >
                      Logout
                    </button>
                  </li>
                </nav>
              </header>
              : <header
                style={headerStyle}
                className="dontPrint"
                >
                <h2 style={{
                  fontFamily: '"Montserrat",sans-serif',
                  fontSize: '1.5rem',
                  padding: '0',
                }}
                >
                  <Link to={
                    physicianName.length > 1
                    ? "/patientinfo"
                    : "/"
                  }
                    style={{
                      textDecoration: 'none',
                      opacity: '0.75',
                      color: '#000',
                      background: 'none',
                      border: 'none',
                      fontFamily: '"Montserrat",sans-serif',
                      fontWeight: '400',
                      fontSize: '1.5rem',
                    }}
                    >
                      {physicianName || "CataractPreop"}
                  </Link>
                </h2>
                <nav style={{
                  display: 'flex',
                  marginRight: '-15px',
                  listStyle: 'none',
                }}>
                  <li style={{
                    margin: 'auto 15px',
                  }}>
                    <Link to={"/login"}
                      style={linkStyle}
                      >
                      Login
                    </Link>
                 </li>
                  {/* <li style={{
                    margin: 'auto 15px',
                  }}>
                    <Link to={"/signup"}
                      style={linkStyle}
                    >
                      Sign-up
                    </Link>
                  </li> */}
                  <li style={{
                    margin: 'auto 15px',
                  }}>
                    <Link to={"/about"}
                      style={linkStyle}
                      >About
                    </Link>
                  </li>
                </nav>
              </header>
      }
      <div style={{margin: '40px 0 0 0'}}></div>
    </div>
  )
}





// <nav
//   style={{
//     display: 'flex',
//   }}>
//   <li style={{
//     margin: 'auto 15px',
//     border: '1px solid gray',
//     borderRadius: '10px',
//     display: 'flex',
//   }}>
//     <Link to={props.linkTo}
//       style={linkStyle}
//        onClick={() => window.location = "/"}
//        >
//        Back
//      </Link>
//   </li>
//   <li style={{
//     margin: 'auto 15px',
//   }}>
//     <Link to={props.linkTo}
//       style={linkStyle}
//        onClick={() => window.location = "/"}
//        >
//        Next
//      </Link>
//   </li>
// </nav>
