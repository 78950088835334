import React , { useState } from 'react'

import { Link } from 'react-router-dom'


export default function FooterLight() {

  const [seeTerms, setSeeTerms] = useState(false)

  return (
    <div
        className='dontPrint'
        style={{
            width: '100%',
            color: "#FFF",
            background: '#292929',
            fontSize: '14px',
            textAlign: 'center',
            padding: "200px 0 0 0",
        }}
    >
        <hr
        style={{
          width: '100%',
          margin: '0',
        }}
      >
      </hr> 
      <div
        style={{
          display:'flex',
          justifyContent: 'space-evenly',
          padding: '3px 0 16px 0',
        }}
      >
        <p
          style={{
            margin: '0',
          }}
        >
          Copyright © 2024 Research Synthase
        </p>
        <Link
          to= '/termsofuse'
          style={{
            margin: '0',
            textDecoration: 'none',
            color: '#FFF'
          }}
        >
          Terms of use
        </Link>
        <p
          style={{
            margin: '0',
          }}
        >
          Contact: cataractpreop@gmail.com
        </p>
      </div>
    </div>
  )
}
