import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom";
import api from "./services/api";

import Navbar from "./partials/Navbar"

export default function QrLandingPage(props) {

  const [transform, setTransform] = useState('')
  const [learnLinkHover, setLearnLinkHover] = useState(false)
  const [loginLinkHover, setLoginLinkHover] = useState(false)

  //take care of encryption
  const CryptoJS = require('crypto-js');

  //get qrdata from URL
  let { id } = useParams();
  //split URL data into token and encrypted qr data
  let qrText = id.split('.')
  //qr token as variable
  let qrToken = qrText[0]
  // console.log(qrToken)

  // use QR Token to get physican preference data
  const getQrEncryptionKey = async () => {
    
    try {
      const res = await api.post("/qrcoderead", {
        qrToken,
      });
  
      if (res.status === 200) {
        let encKey = (res.data[0].rows[0].qr_encryption_key);
        let intermediateText = qrText[1].replace(/-/g, "+").replace(/_/g, "/");
        // console.log(intermediateText)
        let decryptedText = CryptoJS.AES.decrypt(intermediateText, encKey);
        // console.log(decryptedText)
        let qrMeasurements = decryptedText.toString(CryptoJS.enc.Utf8);
        // console.log(qrMeasurements)
        // Split qrMeasurements to use throughout qrVisionSimulator
        let splitter = qrMeasurements.split('&');
        // console.log(splitter)
        // console.log(res.data.rows[0].qr_encryption_key);
        props.setSide(splitter[0]);
        props.setPower(splitter[1]);
        props.setCylinder(splitter[2]);
    
        props.setAxis(splitter[3])
        props.setAdd(splitter[4])
        props.setBcva(splitter[5])
        props.setNs(splitter[6])
        props.setBat(splitter[7])
        props.setBiometerAstig(splitter[8])
        props.setBiometerAxis(splitter[9])
        props.setChordMuTotal(splitter[10])
        props.setTopographerAstig(splitter[11])
        props.setTopographerAxis(splitter[12])
        props.setTopographerSphAb(splitter[13])
        props.setTopographerHoa(splitter[14])
        props.setCodeStatus(splitter[15])
        props.setAllergies(splitter[16])
        props.setLensOption(splitter[17]) 
        props.setQ1(splitter[18])
        props.setQ2(splitter[19])
        props.setQ3(splitter[20])
        props.setQ4(splitter[21])
        props.setQ5(splitter[22])
        props.setQ6(splitter[23])
        props.setQ7(splitter[24])
        props.setQ8(splitter[25])
        props.setQ9(splitter[26])
        props.setQ10(splitter[27])
        props.setQ11(splitter[28])
        props.setQ12(splitter[29])
        props.setQ13(splitter[30])
        props.setQ14(splitter[31])

        // use add-on name to modify addOn array of objects================
        const addOnString = `${splitter[32]}`;
        // console.log(addOnString)
        const addOnArray = addOnString.split(",");
        // console.log(addOnArray)
        const mergedAddOnArr = props.cataractAddOn.map(n => {
          if (addOnArray.includes(n.name)) {
            return { ...n, value: 'true' };
          }
          return n;
        });
        // console.log(mergedAddOnArr)
  
        props.setCataractAddOn(mergedAddOnArr); 
        //===========================================================

  
        //use contraindication name to modify contraindication array of objects
        if (res.data[1].rows.length !== 0 && res.data[1].rows !== undefined) {
          // Extract the contraindication names from the response
          const newArr = res.data[1].rows.flatMap(c => Object.values(c.contraindications).map(m => m.name));
  
    
          // Filter out duplicates and set all contraindication values to false
          const uniqueContraindications = [...new Set(newArr)].map(name => ({ name, value: 'false' }));
    
          // Split the string at the comma and map the contraindications to set their values to true
          const textString = `${splitter[33]}`;
          // console.log(textString)
          const textArray = textString.split(",");
          const mergedArr = uniqueContraindications.map(contra => {
            if (textArray.includes(contra.name)) {
              return { ...contra, value: 'true' };
            }
            return contra;
          });
    
          props.setContraindications(mergedArr); 
      }
    }
  } catch (err) {
    console.error(err.message);
  }
  }
 
  
  // use QR Token to get physican preference data
  const getMdPreferences = async () => {
    try {
      const res = await api.post("/qrcoderead", {
        qrToken,
      });
      if (res.status === 200) {


        // console.log(res.data[0].rows)
        // console.log(res.data[1].rows)
        // console.log(res.data[2].rows)
        // console.log(res.data[3].rows)
        // console.log(res.data[4].rows)
        // console.log(res.data[5].rows)
        // console.log(res.data[6].rows)
        // console.log(res.data[7].rows)
        // console.log(res.data[8].rows)

        if(res.data[1].rows.length > 0){
          props.setLensData(res.data[1].rows)
          Object.entries(res.data[1].rows).map(l => {
            if(l[1].lens_location === 'Sulcus'){
              props.setSulcusLens(l[1].lens_name)
            }
          })
        }

        if (res.data[3].rows.length > 0){
          props.setCriOffer(res.data[3].rows[0].cri_offer)
          props.setCriBioTopoAstigMax(res.data[3].rows[0].cri_bio_topo_astig_max)
          props.setCriBioTopoAxisMax(res.data[3].rows[0].cri_bio_topo_axis_max)
          props.setCriConsentId(res.data[3].rows[0].cri_consent)
          props.setFlacsOffer(res.data[3].rows[0].flacs_offer)
          props.setFlacsConsentId(res.data[3].rows[0].flacs_consent)
        } 

        if(res.data[4].rows.length > 0){
          props.setBiometer(res.data[4].rows[0].biometer_name)
          props.setTopographer(res.data[4].rows[0].topographer_name)
          props.setEquipmentPreference(res.data[4].rows[0].equipment_preference)
          props.setSphAbTarget(res.data[4].rows[0].sph_ab_target)
          props.setHoaMaxPreference(res.data[4].rows[0].hoa_max_preference)
        }

        if(res.data[5].rows.length > 0){
          props.setConsentData(res.data[5].rows)
        }

        if(res.data[6].rows.length > 0){
          props.setUseTutorial(res.data[6].rows[0].use_tutorial)
          props.setUseVideo(res.data[6].rows[0].use_video)
        } else{
          props.setUseTutorial(false)
          props.setUseVideo(false)
        }
       
        if(res.data[7].rows){
          props.setVideoData(res.data[7].rows)
        }
        
        if(res.data[8].rows.length > 0){
          props.setBedsheetData(res.data[8].rows[0].bedsheet_data)
          props.setBedsheetId(res.data[8].rows[0].bedsheet_id)
          props.setBedsheetUse(res.data[8].rows[0].bedsheet_use)
          props.setBedsheetName(res.data[8].rows[0].bedsheet_name)
        }
        

      }
    } catch (err) {
      console.error(err.message);
    }
  }


  useEffect(() => {
    getQrEncryptionKey();
    getMdPreferences();
  }, []);
  

  
  useEffect(() => {
      window.addEventListener('scroll', handleScroll);
    }, []);


  const handleScroll = (e) => {
    let r = window.scrollY;
    let u = r/6
    setTransform(u)
  }

  let blueLinkStyleTrue = {
    fontSize: '22px',
    margin: '0 30px 0 30px',
    padding: '20px',
    background: '#007bff',
    border: '1px solid #007bff',
    borderRadius: '5px',
    color: '#FFF',
  }

  let blueLinkStyleFalse = {
    fontSize: '22px',
    margin: '0 30px 0 30px',
    padding: '20px',
    background: '#FFF',
    border: '1px solid #007bff',
    borderRadius: '5px',
    color: '#007bff',
  }

  let pStyle = {
    fontSize: '20px',
    fontWeight: '300',
    color: '#FFF',
    margin: '20px 0 0 0',
    textAlign: 'center',
  }

  let strongStyle = {
    fontSize: '20px',
    fontWeight: '600',
    color: '#DDDDDD',
    margin: '20px 0 0 0',
  }

  let ballStyle = {
      fontSize: '40px',
      fontWeight: '400',
      color: '#292929',
      margin: '20px auto 0 auto',
      textAlign: 'center',
      width: '50px',
      height: '50px',
      borderRadius: '50%',
      background: '#DDDDDD',
    }


  return (
    <div
      style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      margin: '0 0 300px 0',
    }}>
    <Navbar/>

      <h1
        style={{
          fontSize: '80px',
          fontWeight: '400',
          color: '#595959',
          margin: '40px 0 0 0',
          width: '100%',
          textAlign: 'center',
        }}
        >
        Cataract Preop
      </h1>
      <h3
        style={{
          fontSize: '30px',
          fontWeight: '400',
          color: '#595959',
          margin: '0 0 20px 0',
          width: '100%',
          textAlign: 'center',
        }}>

      </h3>
      <div
        onScroll={handleScroll}
        style={{
          margin: '0 0 50px 0',
          textAlign: 'center',
        }}
        >
        <img
          style={{
            transform: `rotate(${transform}deg)`,
            width: '300px',
            opacity: '0.6'
          }}
          src="/logoImage.png"
          alt="logo"
          >
        </img>
      </div>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px 3vw 0px 3vw',
        margin: '0 0 100px 0'
        }}>
        <Link
          to={'/visionsimulator'}
        >
          <button
            style={
              loginLinkHover === true
                ? blueLinkStyleTrue
                : blueLinkStyleFalse
              }
            onMouseOver ={() => setLoginLinkHover(true)}
            onMouseOut={() => setLoginLinkHover(false)}
            onClick={() => {
              props.setUserType('qrmd')
            }}
            >I'm a clinic
          </button>
        </Link>
        <Link
          to={
            props.useTutorial === 'true' && props.useVideo === 'true'
            ? '/tutorialvideo'
            : props.useTutorial === 'false' && props.useVideo === 'true'
            ? '/tutorialvideo'
            : props.useTutorial === 'true' && props.useVideo === 'false'
            ? '/tutorial1'
            : '/tutorial4'
          }
        >
          <button
            style={
              learnLinkHover === true
                ? blueLinkStyleTrue
                : blueLinkStyleFalse
              }
            onMouseOver ={() => setLearnLinkHover(true)}
            onMouseOut={() => setLearnLinkHover(false)}
            onClick={() => {
              props.setUserType('qrpt')
            }}
            >I'm a patient
          </button>
        </Link>
        
      </div>


      
    </div>
  );
}





// <div style={{
//           background: '#292929',
//           display: 'flex',
//           width: '100vw',
//         }}>
//         <div
//           style={{
//             display: 'flex',
//             flexDirection: 'column',
//             width: '650px',
//             margin: '0 auto',
//           }}>
//           <h3 style={{
//               color: '#DDDDDD',
//               fontSize: '40px',
//               fontWeight: '400',
//               textAlign: 'center',
//               margin: '30px 0 20px 0',
//               textTransform: 'uppercase',
//             }}>
//             Difficult Lens Discussion?
//           </h3>
//           <hr style={{width: '200px'}}/>
//           <p style={{
//             fontSize: '20px',
//             fontWeight: '400',
//             color: '#DDDDDD',
//             margin: '20px 0 0 0',
//             textTransform: 'uppercase',
//             textAlign: 'center',
//           }}>
//             We make it easy for patients to pick the right lens
//           </p>
//           <div style={ballStyle}>
//             1
//           </div>
//           <p style={pStyle}>
//             <span style={strongStyle}>Physicians</span> choose their intraocular lens preferences and contraindications just one time when setting up their account.
//           </p>
//           <div style={ballStyle}>
//             2
//           </div>
//           <p style={pStyle}>
//             <span style={strongStyle}>Technicians</span> enter the patient's biometry data.
//           </p>
//           <div style={ballStyle}>
//             3
//           </div>
//           <p style={pStyle}>
//             <span style={strongStyle}>Patients</span> see a customized representation of each of their lens options so they can pick the lens they like best.
//           </p>
//           <div style={ballStyle}>
//             4
//           </div>
//           <p style={pStyle}>
//             <span style={strongStyle}>Paperwork</span> is consistent and uniform with the correct lens for the correct eye.
//           </p>
//           <hr style={{
//               width: '200px',
//               margin: '30px auto 10px auto',
//             }}/>
//           <p style={pStyle}>
//             Put and end to <span style={strongStyle}>difficult</span> lens discussions
//           </p>
//           <p style={pStyle}>
//             <span style={strongStyle}>Empower</span> patients to make their own choice
//           </p>
//           <p style={pStyle}>
//             <span style={strongStyle}>Minimize</span> in-office error
//           </p>
//           <div style={{
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'center',
//             padding: '0px 3vw 0px 3vw',
//             margin: '30px 0 100px 0'
//             }}>
//             <button
//               style={
//                 learnLinkHover === true
//                   ? blueLinkStyleTrue
//                   : blueLinkStyleFalse
//                 }
//               onMouseOver ={() => setLearnLinkHover(true)}
//               onMouseOut={() => setLearnLinkHover(false)}
//               onClick={() => window.location = "/signup"}
//               >Sign-up
//             </button>
//           </div>
//         </div>
//       </div>